@import "~antd/dist/antd.css";

html,
body {
  margin: 0;
  padding: 0;
  color: rgb(60, 64, 82);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

* {
  box-sizing: border-box;
  appearance: none;
  text-decoration: none;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

#root {
  height: 100%;
  min-height: 100%;
  display: flex;
  flex-direction: column;
}

/* width */
::-webkit-scrollbar {
  height: 9px;
  width: 9px;
}

/* Track */
::-webkit-scrollbar-track {
  border-radius: 3px;
  background: rgba(215, 224, 234, 0.3);
  /* margin: 0px 6px; */
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgba(215, 224, 234, 1);
  border-radius: 16px;
  height: 2px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgb(201, 209, 219);
}

/* ::-webkit-scrollbar-track-piece {
  background: rgba(0, 0, 0, 0.15);
} */

::-webkit-scrollbar-button {
  display: block;
  height: 4px;
  width: 4px;
}
