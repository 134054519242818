.color-container {
  margin-bottom: 16px;
  display: flex;
  justify-content: center;
}
.input-color {
  width: 50%;
  height: 30px;
}
.icon {
  font-size: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}
