.loader-container {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  .loader {
    margin-top: 100px;
  }
}
