.account-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  padding: 0 24px;
  max-width: 400px;
  margin: 0 auto;
  .account-details {
    width: 100%;
  }
  .account-form {
    width: 100%;
  }
}

@media only screen and (min-width: 768px) {
  .account-container {
    width: 400px;
  }
}
